<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn
            class="grey lighten-2 mr-2"
            @click.stop="generate_report"
            :disabled="!date_start || !date_end"
        >
          <v-icon left class="mr-2" color="primary">mdi-printer</v-icon>
          Сформувати
        </v-btn>
        <date-component v-model="date_start" label="Період з" class_="mr-2"/>
        <date-component v-model="date_end" label="Період по"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="form-table-custom mt-4">
          <table style="width: 100%">
            <thead>
              <tr>
                <th>Рахунок</th>
                <th>Сальдо на поч. Дт</th>
                <th>Сальдо на поч. Кт</th>
                <th>Оборот Дт</th>
                <th>Оборот Кт</th>
                <th>Сальдо на кін. Дт</th>
                <th>Сальдо на кін. Кт</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in items" :key="idx">
                <td>
                  {{ item.account }}
                </td>
                <td>
                  {{ item.balance_start_debt }}
                </td>
                <td>
                  {{ item.balance_start_cred }}
                </td>
                <td>
                  {{ item.turnover_debt }}
                </td>
                <td>
                  {{ item.turnover_cred }}
                </td>
                <td>
                  {{ item.balance_end_debt }}
                </td>
                <td>
                  {{ item.balance_end_cred }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import accountingReportAPI from "@/utils/axios/accounting/reports"

export default {
  name: "AccountingReportPage",
  data() {
    return {
      date_start: null,
      date_end: null,
      items: [
        {
          account: '361 (Розрахунки із вітчизняими покупцями)'
        }
      ]
    }
  },
  methods: {
    generate_report() {
      accountingReportAPI.general_report({
        date_start: this.date_start,
        date_end: this.date_end
      })
        .then(response => response.data)
        .then(data => this.items = data)
    }
  }
}
</script>

<style scoped>

</style>